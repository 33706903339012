<template>
  <div class="delivery-methods-container">
    <div>
      <div class="headline-m mb-4">เลือกประเภทการจัดส่ง</div>
      <div>
        <div
          v-if="isShowDeliveryHome"
          :class="['item', { '-is-selected': isDelivery }]"
          @click="onClick('delivery', null)"
        >
          <span class="checkmark"></span>

          <div class="body-s item-label">
            จัดส่งถึงบ้าน
          </div>
          <div class="item-icon">
            <img
              :src="require('@/assets/icon/icon-shipping-type.svg')"
            />
          </div>
        </div>

        <div
          v-if="isShowDeliveryStore"
          :class="[
            'item',
            {
              '-is-selected': isStore,
              '-is-disabled': !disableReceiveByBranch,
            },
          ]"
          @click="onClick('store', null)"
        >
          <span class="checkmark"></span>

          <div class="body-s item-label">
            รับสินค้าหน้าร้าน
          </div>
          <div class="item-icon">
            <img
              :src="
                require('@/assets/icon/icon-pick-at-store-type.svg')
              "
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Delivery -->
    <div
      v-if="
        isDelivery &&
          isShowDeliveryHome &&
          isShowDelivery(campaign.slug)
      "
    >
      <div class="headline-m mb-4">เลือกวิธีจัดส่งสินค้า</div>

      <div>
        <div
          v-for="item of deliveryItems"
          :class="[
            'item delivery-item',
            {
              '-is-selected': item.sku === currentDeliverySku,
              '-is-disabled': !item.active,
            },
          ]"
          :key="item.sku"
          @click="onClickDelivery(item)"
        >
          <span class="checkmark"></span>

          <div class="body-s item-label">
            <div class="delivery-item-name">
              <img
                src="@/assets/icon/studio7/shipping-fast-regular.svg"
                width="auto"
                height="16"
                :alt="item.name"
              />
              {{ item.name }}
            </div>
            <div class="estimated-delivery-times">
              {{ shipDate }}
            </div>
            <!-- <div class="pre-order-expected-message">
              {{ item.description }}
            </div> -->
          </div>
          <div class="item-icon">
            <template v-if="item.price_srp > 0">
              {{ item.price_srp | currency2 }}
            </template>
            <span v-else class="body-s delivery-item-price">
              ฟรี
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- Pick at Store -->
    <div v-if="isStore && isShowDeliveryStore">
      <div class="headline-m mb-4">สาขาที่ต้องการให้จัดส่ง</div>

      <div class="branch-selection-container">
        <div class="branch">
          <v-autocomplete
            v-model="branchProvince"
            data-pptr="branch_province"
            background-color="white"
            class="text-sm"
            placeholder="เลือกจังหวัด"
            ref="branch_time_id"
            outlined
            hide-details
            :error="hasErrorBranchProvince"
            :items="provinceItems"
            :disabled="isDisabledSelectBranch"
            @change="onChangeBranchProvince"
            @click="onFocus(false)"
            @click:append="onFocus(false)"
            @click:prepend="onFocus(true)"
          ></v-autocomplete>
        </div>
        <div class="branch">
          <v-autocomplete
            v-model="branchId"
            data-pptr="branch_time_id"
            background-color="white"
            class="text-sm"
            item-text="name"
            item-value="id"
            placeholder="เลือกสาขา"
            ref="branch_time_id"
            outlined
            hide-details
            :error="hasErrorStore"
            :items="branchData"
            :disabled="isDisabledSelectBranch"
            @change="onChangeBranchId"
            @click="onFocus(false)"
            @click:append="onFocus(false)"
            @click:prepend="onFocus(true)"
          ></v-autocomplete>
        </div>
        <div class="time">
          <v-select
            v-model="form.branch_time_id"
            data-pptr="branch_time_id"
            background-color="white"
            class="text-sm"
            item-text="name"
            item-value="id"
            placeholder="ช่วงเวลา"
            ref="branch_time_id_detail"
            outlined
            hide-details
            :error="hasErrorStore"
            :items="timeRangeData"
            @click="onFocus(false)"
            @click:append="onFocus(false)"
            @click:prepend="onFocus(true)"
          >
            <v-slot slot="selection" slot-scope="{ item }">
              <div class="v-select__selection">
                {{ item.name }}
              </div>
            </v-slot>
            <v-slot slot="item" slot-scope="{ item }">
              <div>
                {{ item.name }}
              </div>
            </v-slot>
          </v-select>

          <div class="time-description">
            *เป็นเวลาประมาณการ
            ต้องขออภัยอาจมีการเปลี่ยนแปลงตามสถานการณ์
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import colorBnn from '@/config/color'
import branchTypes from '@/config/branchTypes'
import { mapGetters, mapState } from 'vuex'
import { validationMixin } from 'vuelidate'
import { requiredIf } from 'vuelidate/lib/validators'
import { isShowDelivery } from '@/utils/helpers'

export default {
  name: 'CheckoutDeliveryMethod',

  components: {},

  mixins: [validationMixin],

  computed: {
    ...mapState({
      // branchData: state => state.branchStore.data,
      selectSKU: state => state.model.selectSKU,
      delivery: state => state.cart.delivery,
      campaign: state => state?.campaign?.current?.data,
      branchType: state => state.branchStore.branchType,
      isLoading: state => state.branchStore.isLoading,
      selectDelivery: state => state?.bundle.delivery,
      paymentTypeData: state => state.paymentType.data,
    }),

    ...mapGetters({
      productItem: 'cart/productItem',
      deliveryItems: 'model/getBundleDelivery',
      disabledBranchDeposit: 'branchStore/disabledBranchDeposit',
      disabledBranchFullPayment:
        'branchStore/disabledBranchFullPayment',
      provinceItems: 'branchStore/getProvince',
      cartItems: 'cart/cartItems',
    }),

    shipDate() {
      return this.selectSKU.shipping_date || this.campaign?.shipdate
    },

    branchData() {
      return this.$store.getters['branchStore/getBranchByProvince'](
        this.branchProvince,
      )
    },

    disableReceiveByBranch() {
      return (
        !this.disabledBranchDeposit || !this.disabledBranchFullPayment
      )
    },

    isDisabledSelectBranch() {
      // if has branch id param in url, disable select branch but if branch full enable select branch

      const queryParam = this.$route?.query

      if (!queryParam?.admin_quota?.toLowerCase() === 'true') {
        return false
      }

      if (queryParam?.branch_code) {
        const branchCode = queryParam?.branch_code
          ? queryParam?.branch_code.toLowerCase()
          : null
        return (
          this.branchData?.some(
            i => +i?.branch_code === +branchCode,
          ) && !this.isFullTimeRangeData
        )
      } else if (queryParam?.branch_id) {
        const branchId = queryParam?.branch_id
          ? queryParam?.branch_id.toLowerCase()
          : null
        return (
          this.branchData?.some(i => +i?.id === +branchId) &&
          !this.isFullTimeRangeData
        )
      }

      return false
    },

    hasWarrantyInCart() {
      return this.cartItems.some(item => item?.type === 'warranty')
    },

    isDelivery() {
      return this.delivery === 'delivery'
    },

    isStore() {
      return this.delivery === 'store'
    },

    isDeposit() {
      return this.branchType === branchTypes.BRANCH_TYPE_DEPOSIT
    },

    isFullPayment() {
      return this.branchType === branchTypes.BRANCH_TYPE_FULL_PAYMENT
    },

    disabledDeliveryStore() {
      return this.$store.getters[
        'branchStore/disabledDeliveryStore'
      ]()
    },

    timeRangeData() {
      return this.$store.getters['branchStore/getTimesByBranchId'](
        this.branchId,
      )
    },

    isFullTimeRangeData() {
      return (
        this.timeRangeData?.filter(i => i.active === true)?.length ===
        0
      )
    },

    timeRangeDataNotActive() {
      return this.$store.getters[
        'branchStore/getTimesByBranchIdNotActive'
      ](this.branchId)
    },

    receiveByBranch() {
      if (this.isDeposit) {
        return true
      } else {
        return this.checkReceiveBranch
      }
    },

    isShowDeliveryHome() {
      // EDIT: Condition hide delivery home
      // - select deposit payment
      // - bundle has 'warranty'
      // - has query param stock card

      if (
        this.isDeposit ||
        this.hasWarrantyInCart ||
        this.$route.query.t
      ) {
        return false
      }

      return this.campaign?.is_delivery_home ?? false
    },

    isShowDeliveryStore() {
      // EDIT: If select full payment and from online (not from stock card) hide delivery store
      if (this.isFullPayment && !this.$route.query.t) {
        return false
      }

      return this.campaign?.is_delivery_store ?? false
    },

    isShowBtnDeposit() {
      if (this.campaign?.btn_deposit) {
        return (
          this.paymentTypeData?.filter(i => i.type === 'deposit')
            ?.length > 0
        )
      }
      return false
    },

    isShowBtnFullPayment() {
      if (
        this.campaign?.btn_full_customer ||
        this.campaign?.btn_full_staff
      ) {
        return (
          this.paymentTypeData?.filter(i => i.type !== 'deposit')
            ?.length > 0
        )
      }
      return false
    },

    currentDeliverySku() {
      return this?.selectDelivery
        ? Object.keys(this?.selectDelivery)?.[0] ?? null
        : null
    },
  },

  validations: {
    form: {
      branch_time_id: {
        required: requiredIf(function() {
          return this.receiveByBranch
        }),
      },
      branchProvince: {
        required: requiredIf(function() {
          return this.receiveByBranch
        }),
      },
    },
  },

  watch: {
    branchProvince(value) {
      this.form.branch_province = value
    },
    timeRangeData(items) {
      if ((items || []).length === 1) {
        this.form.branch_time_id = items[0].id
      } else {
        this.form.branch_time_id = null
      }
    },

    disableReceiveByBranch() {
      this.autoSelectDelivery()
    },
  },

  props: {
    form: {
      type: Object,
      default: () => ({}),
    },

    hasErrorReceiveOrder: {
      type: Boolean,
      default: false,
    },

    hasErrorStore: {
      type: Boolean,
      default: false,
    },

    hasErrorBranchProvince: {
      type: Boolean,
      default: false,
    },
  },

  async mounted() {
    // await this.$store.dispatch('branchStore/getRefetchBranch')
    await this.getRefetchBranch()
    this.$nextTick(() => {
      this.autoSelectDelivery()
      if (this.$route?.query?.branch_code) {
        this.branchProvince = this.$store.getters[
          'branchStore/getProvinceByBranchCode'
        ](this.$route?.query?.branch_code)

        this.branchId = this.$store.getters[
          'branchStore/getBranchIdByBranchCode'
        ](this.$route?.query?.branch_code)

        this.$store.commit(
          'branchStore/setSelectBranch',
          this.branchId,
        )

        return
      } else if (this.$route?.query?.branch_id) {
        this.branchProvince = this.$store.getters[
          'branchStore/getProvinceByBranchId'
        ](this.$route?.query?.branch_id)

        this.branchId = this.$route?.query?.branch_id
          ? +this.$route?.query?.branch_id.toLowerCase()
          : null

        this.$store.commit(
          'branchStore/setSelectBranch',
          this.branchId,
        )

        return
      }
    })
  },

  data() {
    return {
      isShowDelivery,
      colorBnn,
      branchId: null,
      checkReceiveBranch: false,
      branchProvince: null,
      //   form: {
      //     branch_time_id: undefined,
      //   },
    }
  },

  methods: {
    async getRefetchBranch() {
      const queryParam = this.$route?.query
      const adminQuota = queryParam?.admin_quota
        ? queryParam?.admin_quota.toLowerCase() === 'true'
        : null

      const payload = {
        productId: this.productItem?.id,
        adminQuota: adminQuota ? 1 : 0,
      }
      await this.$store.dispatch(
        'branchStore/getRefetchBranch',
        payload,
      )
    },

    onFocus(value) {
      this.$store.dispatch('cart/showSummary', value)
      this.$forceUpdate()
    },

    onClick(value) {
      this.$store.commit('cart/delivery', value)
      if (value === 'delivery') {
        // this.$store.commit(
        //   'branchStore/setBranchType',
        //   branchTypes.BRANCH_TYPE_FULL_PAYMENT,
        // )
        this.$store.dispatch(
          'bundle/selectDelivery',
          this.deliveryItems?.[0],
        )
      } else {
        // this.$store.commit(
        //   'branchStore/setBranchType',
        //   branchTypes.BRANCH_TYPE_DEPOSIT,
        // )
        this.$store.dispatch('bundle/selectDelivery', null)
        // this.$store.dispatch('branchStore/getRefetchBranch')
        this.getRefetchBranch()
      }
      this.$forceUpdate()

      // if (item) {
      //   this.$store.dispatch('bundle/selectDelivery', item)
      // }
      // if (item === this.BRANCH_TYPE_DEPOSIT) {
      //   this.$store.commit(
      //     'branchStore/setBranchType',
      //     branchTypes.BRANCH_TYPE_DEPOSIT,
      //   )
      //   this.$store.dispatch('branchStore/getRefetchBranch')
      //   this.$store.dispatch('bundle/selectDelivery', null)
      // }
    },

    onClickDelivery(item) {
      if (item) {
        this.$store.dispatch('bundle/selectDelivery', item)
        this.$forceUpdate()
      }
    },

    onClickFull() {
      this.$store.commit(
        'branchStore/setBranchType',
        branchTypes.BRANCH_TYPE_FULL_PAYMENT,
      )
      // this.$store.dispatch('branchStore/getRefetchBranch')
      this.getRefetchBranch()
      this.resetBranchTime()
    },

    onClickDeposit() {
      this.$store.commit(
        'branchStore/setBranchType',
        branchTypes.BRANCH_TYPE_DEPOSIT,
      )
      // this.$store.dispatch('branchStore/getRefetchBranch')
      this.getRefetchBranch()
      this.resetBranchTime()
    },

    strHaveBR(str) {
      // return str.match(/(<br>|<br\/>)/)
      return str
    },

    onChangeBranchProvince() {
      this.$store.dispatch('branchStore/resetBranchSelect')
      this.resetBranchTime()
    },

    onChangeBranchId(value) {
      this.$store.commit('branchStore/setSelectBranch', value)

      this.resetBranchTime()
    },

    resetBranchTime() {
      this.form.branch_time_id = null
    },

    autoSelectDelivery() {
      if (this.isShowDeliveryHome) {
        this.onClick('delivery', null)
      } else {
        this.onClick('store', null)
      }

      //   if (this.isShowDeliveryStore) {
      //     if (!this.disableReceiveByBranch && !this.isLoading) {
      //       console.log('isShowDeliveryStore')
      //       this.$store.commit('cart/delivery', 'delivery')
      //       // this.$store.commit(
      //       //   'branchStore/setBranchType',
      //       //   branchTypes.BRANCH_TYPE_FULL_PAYMENT,
      //       // )

      //       if (!this.currentDeliverySku) {
      //         this.$store.dispatch(
      //           'bundle/selectDelivery',
      //           this.deliveryItems?.[0],
      //         )
      //       }
      //     }
      //   } else {
      //     this.$store.commit('cart/delivery', 'delivery')
      //     if (!this.currentDeliverySku) {
      //       this.$store.dispatch(
      //         'bundle/selectDelivery',
      //         this.deliveryItems?.[0],
      //       )
      //     }
      //   }

      //   if (this.isDelivery) {
      //     !this.currentDeliverySku
      //       ? this.$store.dispatch(
      //           'bundle/selectDelivery',
      //           this.deliveryItems?.[0],
      //         )
      //       : null
      //   }
    },
  },
}
</script>

<style lang="stylus" scoped>

.delivery-methods-container{
    display: flex;
    flex-direction: column;
    gap: 26px;
}

.action-layout
  display: flex
  width: 100%
  gap: 12px
  @media screen and (max-width: 960px)
    flex-wrap: wrap
  .store
    width: 50%
    padding: 12px 0
    @media screen and (max-width: 960px)
      width: 100%
  .delivery
    width: 50%
    padding: 12px 0
    @media screen and (max-width: 960px)
      width: 100%
.payment-type-layout
  display: flex
  width: 100%
  gap: 12px
  @media screen and (max-width: 960px)
    flex-direction: column
    width 100%
  .deposit
    width: 50%
    padding: 12px 0
    @media screen and (max-width: 960px)
      width: 100%

  .full-payment
    width: 50%
    padding: 12px 0
    @media screen and (max-width: 960px)
      width: 100%

::v-deep .v-select__selection
    max-width: 100%

.text-warning
  font-size: 0.875rem
  color: $color-red

  @media tablet
    font-size: 0.875rem

  @media small
    font-size: 11px

.delivery-item.item {
    align-items: baseline;
}
.item {
    display: flex;
    align-items: center;
    gap: 11px;
    padding: 11px;

    border: 1.2px solid $color-dapp-gray-3;
    cursor: pointer;

    &:first-child&:last-child {
        border-radius: 8px;
    }

    &:first-child:not(:last-child) {
        border-radius: 8px 8px 0 0;
    }

    &:last-child:not(:first-child) {
        border-radius: 0 0 8px 8px;
    }

    &:not(:last-child) {
        border-bottom: none;
    }

    &:last-child:not(:first-child):not(.-is-selected) {
        border-top: none;
    }

    &.-is-selected {
        background-color: $color-fill-radio-button;
        border: 1.2px solid $color-bnn !important;

        .checkmark {
            border: 5.5px solid $color-bnn;
        }
    }

    &.-is-remove-border-top {
        border-top: none;
    }

    &.-is-disabled {
        opacity: 0.4;
        cursor: not-allowed;
        pointer-events: none;
    }

    .item-label {
        flex: 1;

        > .label {
            font-weight: 500;
            color: rgba(#000000, 0.70);
        }

        > .description-s {

            font-size: 12px;
            font-weight: 600;
            line-height: 1.3;
            color: rgba(#000000, 0.45);
        }
    }

    .item-icon {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            object-fit: contain;
            width: 100%;
            height: 100%;
        }
    }

    .checkmark {
        width 16px;
        height 16px;
        border-radius: 50%;
        border: 1px solid #d2d2d7;
        transition: border .1s;
    }

}

.base-radio-button {
	position: relative;
	display: block;
	cursor: pointer;
	user-select: none;

	&.-is-disabled {
		cursor: not-allowed;

		> .label {
			color: $color-absolute-black-45;
		}
	}

	// Hide the browser's default radio
	input {
		display: none;
		opacity: 0;
		width: 0;
		height: 0;
		cursor: pointer;
	}

	// Create a custom radio
	.checkmark {
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		height:  16px;
		width: 16px;

		display: flex;
		align-items: center;
		justify-content: center;

		background-color: transparent;
		border-radius: 50%;
		border: 1px solid $color-grey83;
		transition: border 0.1s;
	}

	& input:checked ~ .checkmark {
		border: rem(5.5) solid $color-bnn;
	}

	& input:focus ~ .checkmark {
		box-shadow: 0 0 0 rem(3) $color-functionality-secondary;
	}


	& input:not(:checked):disabled ~ .checkmark {
		border: 2px solid $color-grey-light-50;
	}

	& input:checked:disabled ~ .label,
	& input:not(:checked):disabled ~ .label {
		color: $color-grey-light-50;
	}


	& input:checked:disabled ~ .checkmark {
		border: rem(7) solid $color-grey-light-50;
	}
}

.delivery-item-name {
    font-family: "SF Pro Text";
    font-size: .875rem;
    font-style: normal;
    display: flex;
    align-items: center;
    line-height: 23px

    img {
        width: auto;
        height: 16px;
        margin-right: 8px;
    }
}

.estimated-delivery-times {
    font-weight: 500;
    color: rgba(0, 0, 0, .7);
    font-family: "SF Pro Text";
    font-size: .875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.pre-order-expected-message {
    margin-top: 1rem;
    font-size: .75rem;
    font-weight: 600;
    line-height: 1.3;
    color: rgba(0, 0, 0, .45);
}

.delivery-item-price {
    font-weight: 600;
    line-height: 1.25012rem;
}

.branch-selection-container
  max-width 100%
  display grid
  justify-content center
  align-items center
  gap 14px
  grid-template-columns: repeat(1, 1fr)
  @media screen and (min-width: 768px)
    grid-template-columns: repeat(2, 1fr)
  .branch
    width 100%
  .time
    width 100%
    grid-column: span 1
    @media screen and (min-width: 768px)
      grid-column: span 2

.time-description
    width: 100%
    text-align: left;
    font-size: 14px;
    color: $color-dark-gray-medium;
    margin-top: 8px;
</style>
